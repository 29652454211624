@charset "utf-8";

$ja: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
$baseColor: #000;
$lightBlue: #8ecfe2;
$transition01: all 500ms cubic-bezier(.215, .61, .355, 1);				



@mixin for-desktop {
  @media (min-width: 1024px) { @content; }
}

@mixin for-tablet {
  @media (max-width: 1024px) { @content; }
}

@mixin for-mid-tablet {
  @media (max-width: 900px) { @content; }
}

@mixin for-phone {
  @media (max-width: 600px) { @content; }
}

@mixin for-mid-phone {
  @media (max-width: 400px) { @content; }
}

@mixin for-sm-phone {
  @media (max-width: 320px) { @content; }
}



/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

body {
  line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
	display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000; 
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  display:block;
  height:1px;
  border:0;   
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
}



/**
*
*	common properties
*
**/

html {
	font-size: 62.5%;
}

body {
  color: $baseColor;
	font-family: $ja;
	font-size: 16px;
	font-size: 1.6rem;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  border: none;
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

img, a {
  border: none;
  outline: none;
}

label {
  cursor: pointer;
}

p {
  line-height: 1.6em;
}

main {
  display: block;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

ol, ul {
  list-style: none;
}

a {
  &:link, &:visited {
    color: $baseColor;
    text-decoration: none;
    transition: $transition01;
  }
  &:hover, &:active {
    opacity: 0.7;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ins {
  display: inline-block;
  vertical-align: bottom;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.no-scroll {
	overflow-y: hidden;
}

.sp-hidden {
  @include for-phone {
    display: none;
  }
}

.sp-visible {
  display: none;
  @include for-phone {
    display: block;
  }  
}

.sp-visible-inline {
  display: none;
  @include for-phone {
    display: inline;
  }  
}

.cols {
  display: flex;
  flex-wrap: wrap;
  @include for-phone {
    display: block;
  }   
  .col {
    width: 50%;
    @include for-phone {
      width: auto;
    }       
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
  max-width: 1080px;
  @include for-phone {
    padding-right: 6.67%;
    padding-left: 6.67%;
  }
}

.site-header {
  padding: 12px 0;
  text-align: center;
  background-color: #000;
  .logo {
    img {
      @include for-phone {
        width: 215px;
      }         
    }
  }  
}

.site-footer {  
  font-size: 1rem;
  text-align: center;
  background-color: #fff;
  .container {
    padding: 26px 0;
    @include for-phone {
      padding-bottom: 120px;
    }      
  }
  .copyright {
    letter-spacing: 0.1em;
  }
}

.home-page {
  background: url(../img/home_bg.jpg) no-repeat center top;
  .intro-section {
    text-align: center;
    .container {
      padding-top: 80px;
      padding-bottom: 64px;
      @include for-phone {
        padding-top: 35px;
      }
    }
    .lead {
      margin-top: 64px;
      font-size: 2.1rem;
      line-height: 2.8em;
      @include for-phone {
        margin-top: 30px;
        font-size: 1.3rem;
        line-height: 2.6em;
      }      
      strong {
        display: inline-block;
        position: relative;
        font-size: 2.7rem;
        @include for-phone {
          font-size: 1.7rem;
        }          
        &::before {
          position: absolute;
          left: 0;
          bottom: 0.3em;
          width: 100%;
          height: 15px;
          background-color: rgba(142,207,226,0.5);
          content: "";
          @include for-phone {
            height: 10px;
          }            
        }
        span {
          position: relative;
        }
      }
    }    
  }
  .columns-section {
    background-color: rgba(142,207,226,0.5);
    .section-title {
      margin-bottom: 25px;
      text-align: center;
      img {
        @include for-phone {
          width: 137px;
        }
      }
    }  
    .section-lead {
      margin-bottom: 40px;
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center;
      @include for-phone {
        margin-bottom: 24px;
      }      
    }    
    .container {
      padding-top: 20px;
      padding-bottom: 20px;
      @include for-phone {
        margin-top: 30px;
        font-size: 1.3rem;
        line-height: 2.6em;
      }        
    }
  }
}

.posts {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px;
  @include for-mid-tablet {
    margin-right: -15px;
    margin-left: -15px;
  }   
  @include for-phone {
    display: block;
    margin-right: 0;
    margin-left: 0;
  }
  .post {
    margin-bottom: 40px;
    padding: 0 30px;
    width: 50%;
    @include for-mid-tablet {
      padding: 0 15px;
    }     
    @include for-phone {
      padding: 0;
      width: auto;
    }   
    a {
      &:link, &:visited {
        display: block;
      }
    } 
  }
  .post-info {
    position: relative;
  }
  .post-number {
    position: absolute;
    left: 30px;
    top: -36px;    
    padding-top: 20px;
    background: url(../img/icon_house_01.svg) no-repeat center center;
    width: 96px;
    height: 58px;
    color: #fff;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    text-align: center;
    @include for-phone {
      left: 20px;
      width: 85px;
      height: 50px;
      font-size: 1.2rem;
      background-size: contain;
    }       
    span {
      font-size: 2rem;
      @include for-phone {
        font-size: 1.8rem;
      }       
    }    
  }
  .post-date {
    padding-top: 10px;
    padding-bottom: 16px;
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    text-align: right;
  }
  .post-title {
    padding: 0 30px;
    font-weight: 500;
    @include for-phone {
      padding: 0 20px;
    }      
  }
}

.sticky-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @include for-mid-tablet {
    width: 64px;
  }     
  @include for-phone {
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    text-align: center;
    transform: none;
    img {
      max-width: 375px;
    }
  }  
}

.section-contents-wrapper {
  padding-bottom: 48px;
  border: 2px solid #000;
  border-radius: 12px;
  background-color: #fff;
  @include for-mid-tablet {
    padding-right: 24px;
    padding-left: 24px;
  }   
  @include for-phone {
    padding-bottom: 24px;
  }    
}

.catalog-use-page {
  background: url(../img/bg_texture_01.png);
  .page-header {
    background: #cce8f1 url(../img/catalog_use_header_bg.jpg) no-repeat right top;  
    .container {
      @include for-phone {
        padding-right: 0;
        padding-left: 0;
      }
    }  
  }
  .points-section {
    .container {
      padding-top: 64px;
      padding-bottom: 64px;
      max-width: 880px;
      @include for-phone {
        padding-top: 24px;
        padding-bottom: 24px;
      }      
    }
    .block {
      margin-bottom: 64px;
      padding-left: 64px;
      @include for-phone {
        padding-left: 0;
      }        
    }
    .block-maker {
      .col {
        &:last-of-type {
          margin-top: 24px;
        }
      }
    }
    .block-talk {
      .block-title {
        margin-bottom: 24px;
      }
    }
    .block-title {
      position: relative;
      margin-bottom: 16px;
      color: #81bcce;
      font-size: 3.3rem;
      line-height: 1.2em;
      @include for-phone {
        padding-left: 36px;
        font-size: 1.9rem;
      }       
      .index {
        position: absolute;
        left: -64px;
        top: 6px;
        @include for-phone {
          left: 0;
          top: 50%;
          margin-top: 1px;
          transform: translateY(-50%);
          img {
            width: auto;
            height: 42px;
          }
        }           
      }
    }
    .block-content {      
      line-height: 1.6em;
      p {
        margin-bottom: 24px;
        @include for-phone {
          margin-bottom: 16px;
        }         
      }
    }
    .cols {
      margin-right: -12px;
      margin-left: -12px;
      @include for-phone {
        margin-right: 0;
        margin-left: 0;
      }        
      .col {
        padding: 0 12px;
        @include for-phone {
          margin-bottom: 8px;
          padding: 0;
        }          
      }
    }
  }
  .section-title {
    margin-top: -18px;
    margin-bottom: 32px;
    text-align: center;
    @include for-phone {
      margin-right: -6px;
      margin-left: -6px;
      margin-top: -10px;
    }      
  }
  .steps {
    display: flex;
    margin-right: -24px;
    margin-left: -24px;
    padding: 0 70px;
    @include for-mid-tablet {
      margin-right: -15px;
      margin-left: -15px;
      padding: 0 0;
    }
    @include for-phone {
      display: block;
      margin-right: 0;
      margin-left: 0;
      padding: 0 20px;
    }     
    li {
      padding: 0 24px;
      width: 33.33%;
      @include for-mid-tablet {
        padding: 0 15px;
      }      
      @include for-phone {
        margin-bottom: 30px;
        padding-right: 0;
        padding-left: 0;
        width: auto;
      }        
    }
    .step-index {
      margin: 0 auto 12px auto;
      padding-top: 24px;
      width: 96px;
      height: 58px;
      color: #fff;
      font-size: 1.3rem;
      letter-spacing: 0.05em;
      text-align: center;
      background: url(../img/icon_house_01.svg) no-repeat center center;
      @include for-phone {
        padding-top: 16px;
        width: 70px;
        height: 42px;
        font-size: 1.1rem;
        background-size: contain;
      }        
      strong {
        font-size: 2.4rem;
        @include for-phone {
          width: 70px;
          height: 42px;
          font-size: 1.9rem;
        }         
      }
    }
    .step-description {
      margin-bottom: 16px;
      font-size: 1.2rem;
    }
    .step-image {
      text-align: center;
      img {
        max-width: 210px;
        @include for-mid-tablet {
          max-width: 80%;
        }   
      }
    }
  }
}

.showrooms-section {
  margin-top: 140px;
  @include for-phone {
    margin-top: 64px;
  }    
  .container {
    padding-bottom: 64px;
  }  
  .section-title {
    margin-top: -45px;
    @include for-phone {
      margin-top: 0;
      transform: translateY(-50%);
      img {
        width: 92%;
      }
    }     
  }
  .showrooms {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 680px;
    @include for-phone {
      display: block;
      padding-right: 20px;
      padding-left: 20px;
    }      
  }
  .showrooms-prefecture {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    width: 190px;
    color: #fff;
    font-size: 2.6rem;
    font-weight: 700;
    text-align: center;
    border-radius: 8px;
    background-color: $lightBlue;
    @include for-phone {
      display: block;
      padding: 8px 16px 10px 16px;
      width: auto;
      font-size: 1.6rem;
      border-radius: 3px;
    }     
  }
  .showrooms-list-wrapper {
    margin-bottom: 18px;
    padding-left: 30px;
    width: calc(100% - 190px);
    @include for-phone {
      padding-left: 0;
      width: auto;
    }      
  }
  .showrooms-list {
    margin-bottom: 10px;
  }
  .showroom-name {
    position: relative;
    padding-left: 27px;
    color: $lightBlue;
    font-size: 2.3rem;
    font-weight: 600;
    @include for-phone {
      padding-left: 20px;
      font-size: 1.9rem;
    }     
    &::before {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 0 14px 17px;
      border-color: transparent transparent transparent $lightBlue;
      content: "";
      @include for-phone {
        top: 7px;
        border-width: 9px 0 9px 12px;
      }        
    }
  }
  a {
    &:link, &:visited {
      color: $lightBlue;
    }
  }
  .showroom-info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
    font-size: 1.3rem;
    line-height: 1.6em;
    @include for-phone {
      display: block;
    }      
    li {
      margin-right: 24px;
      @include for-phone {
        display: flex;
      }        
      &:last-of-type {
        width: 100%;       
      }
      .label {
        display: inline-block;
        position: relative;
        margin-right: 8px;
        width: 50px;
        @include for-phone {
          width: 50px;
        }          
        &::after {
          position: absolute;
          right: 0;
          top: 3px;
          width: 2px;
          height: 14px;
          background-color: $lightBlue;
          content: "";
        }
      }
      .content {
        flex: 1;
      }      
    }
    .showroom-tel {
      .label {
        width: 35px;
        @include for-phone {
          width: 50px;
        }          
      }
    }
  }
}

.column-page {
  .page-header {
    .container {
      padding-top: 36px;
      padding-bottom: 30px;
      @include for-phone {
        padding-top: 15px;
        padding-bottom: 15px;
      }      
    }    
    text-align: center;
    background-color: #cce8f1;
  }
  .page-title {
    margin-bottom: 24px;
    @include for-phone {
      margin-bottom: 10px;
    }      
    img {
      @include for-phone {
        width: 135px;
      }
    }
  }
  .column-date {
    font-size: 1.5rem;
    letter-spacing: 0.12em;
    @include for-phone {
      font-size: 1.1rem;
    }      
  }
  .column-index {
    margin: 0 auto;
    padding-top: 24px;
    width: 96px;
    height: 58px;
    color: #fff;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    background: url(../img/icon_house_01.svg) no-repeat;
    @include for-phone {
      padding-top: 16px;
      width: 70px;
      height: 40px;
      font-size: 1rem;
      background-size: contain;
    }     
    strong {
      font-size: 2rem;
      @include for-phone {
        font-size: 1.4rem;
      }       
    }
  }
  .column-title {
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.8em;
    text-align: center;
    @include for-phone {
      margin-bottom: 24px;
      font-size: 1.8rem;
    }        
  }
  .contents-section {
    .container {
      padding-top: 40px;
      padding-bottom: 120px;
      @include for-phone {
        padding-top: 24px;
        padding-bottom: 40px;
      }       
    }
  }
  .hero-image {
    margin-bottom: 64px;
    @include for-phone {
      margin-bottom: 24px;
    }      
  }
  .blocks {
    margin: 0 auto;
    max-width: 810px;
    .block {
      .block-title {
        position: relative;
        margin-bottom: 2px;
        font-size: 1.9rem;
        line-height: 1.6em;
        @include for-phone {
          font-size: 1.8rem;
        }          
      }
      p {
        margin-bottom: 32px;
        @include for-phone {
          font-size: 1.5rem;
        }          
      }
      hr {
        margin: 12px 0;
        border: 3px solid #000;
        border-radius: 5px;
      }
    }
  }
  .credit-section {
    background-color: #cce8f1;
    .container {
      padding-top: 60px;
      padding-bottom: 60px;
      max-width: 890px;
      @include for-phone {
        padding-top: 30px;
        padding-bottom: 30px;
      }        
    }
    .portrait {
      float: left;
      padding-right: 30px;
      width: 190px;
      @include for-phone {
        padding-right: 15px;
        width: 135px;
      }        
    }
    .author-profile {
      p {
        float: left;
        margin-bottom: 2em;
        width: calc(100% - 190px);
        font-size: 1.3rem;
        letter-spacing: -0.02em;
        @include for-phone {
          margin-bottom: 0;
          width: calc(100% - 135px);
        } 
        &:last-of-type {
          @include for-phone {
            margin-top: 10px;
            font-size: 1.1rem;
            width: auto;
          }          
        }        
      }
    }
    .copyright {
      margin-top: 24px;
      font-size: 1rem;
      letter-spacing: 0.05em;     
    }
  }
}

.not-found-page {
  .page-title {
    margin-bottom: 24px;
    font-size: 3.2rem;
    font-weight: 500;
  }
  .contents-section {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    p {
      margin-bottom: 1em;
    }
  }
}